export const do_inSiteReg = (regdata) => {
  // console.log("outside insite", regdata);

  if (typeof window.isLog === "function") {
    const insdata = { meta: { registration: "Online" } };
    //console.log("inside insite", regdata);

    Object.keys(regdata).map((name, i) => {
      const value = regdata[name];

      switch (name) {
        case "NAME":
          var res = value.split(" ");
          var last = "";
          insdata["first_name"] = res[0];
          if (res.length > 1) {
            res.shift();
            last = res.join(" ");
          }
          insdata["last_name"] = last;
          break;
        case "EMAIL":
          insdata["email"] = value;
          break;
        case "PHONE":
          insdata["phone"] = value;
          break;
        case "PURCHASE_T":
          insdata["meta"]["purchase_timeframe"] = value;
          break;
        case "CONTACT_BY":
          insdata["meta"]["contact_method"] = value;
          break;
        case "PKG_URL":
          break;
        case "PKG_ID":
          insdata["meta"]["package_id"] = value;
          break;
        case "PKG_NAME":
          break;
        case "POST_CODE":
          insdata["meta"]["post_code"] = value;
          break;
        default:
          insdata["meta"][name] = value;
      }

      return false;
    });
    // console.log("ins data", insdata);

    window.isLog("Register User", insdata);
  }
};

export const loadInsite = () => {
  if (typeof window !== "undefined" && typeof window.client === "function") {
    window.client("ebfea2de");
  }
};
